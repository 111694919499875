






































































































import { Component, Mixins } from 'vue-property-decorator'
import { Authentication, AuthServiceType, ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors, Inject, logger } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { Form } from '../../../shared/molecules/Form'
import { UserModel } from '../../../auth/shared'

import { IProductReviewsRepository, ReviewsRepositoryType } from '../../contracts/repositories'

import { addReviewModalValidationMap } from './AddReviewModal.helpers'

const CLOSE_MODAL_ON_IS_SUBMITTED_TIMEOUT = 2000

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AddReviewModal>({
  name: 'AddReviewModal',
  components: { Form },
  created (): void {
    this.fillFormData()
  }
})
export class AddReviewModal extends Mixins(AbstractModal, FormErrorsMixin) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(ReviewsRepositoryType)
  protected readonly reviewsRepository!: IProductReviewsRepository

  /**
   *  Validations rules map.
   */
  public readonly validatorsMap = addReviewModalValidationMap

  /**
   * Timer index
   */
  private tIndex: number | null = 0

  /**
   * State of form
   */
  public isSubmitted: boolean | null = null

  /**
   * Error message
   */
  public errorMessage: string | undefined = undefined

  /**
   * Forms payload.
   */
  public formData = {
    firstname: '',
    comment: '',
    rate: 0
  }

  /**
   * Disable state of button.
   */
  public get isDisabled (): boolean {
    return this.formData.rate === 0
  }

  public async addReview (): Promise<void> {
    this.isSubmitted = null
    this.errorMessage = undefined

    try {
      await this.reviewsRepository.createProductReview({
        ratingConfigId: '',
        author: this.formData.firstname,
        sku: this.payload.sku,
        summary: '',
        content: this.formData.comment,
        ratingConfigValue: this.formData.rate.toString()
      })

      this.isSubmitted = true
    } catch (e) {
      this.errorMessage = (e as Error).message
    }
  }

  public close (): void {
    this.tIndex = null
    this.closeCb()
  }

  public fillFormData (): void {
    if (this.authService.check()) {
      this.formData.firstname = this.authService.user?.firstName ?? ''
    }
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.isSubmitted = true

    if (typeof this.errorMessage === 'undefined') {
      this.tIndex = window.setTimeout(() => {
        this.close()
        window.clearTimeout(this.tIndex!)
      }, CLOSE_MODAL_ON_IS_SUBMITTED_TIMEOUT)
    }
  }

  /**
   * Handles @fail event of `Form`
   */
  public onFail (error: any): void {
    this.isSubmitted = false

    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.errorMessage = error.message
  }
}

export default AddReviewModal
